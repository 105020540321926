import React, { useContext, useEffect } from 'react';
import queryString from 'query-string';

import { FormContext } from '../Form/context';
import StoreData from '../../data/store';

export default function AutoSave({ formKey, query }) {
  const { values, bulkChange } = useContext(FormContext);

  const loadLocalDraft = () => {
    const localDraft = localStorage.getItem(`draft`);

    if (localDraft) {
      const draftVals = StoreData.decrypt(localDraft);
      if (draftVals.formKey === formKey) {
        bulkChange(draftVals);
      }
    } else {
      const options = queryString.parse(query);
      const country = localStorage.getItem(`country`);

      bulkChange({ country });

      if (options.saved && options.email) {
        bulkChange({ email_address: options.email, country });
      }
    }
  };

  const saveDraft = e => {
    if (e) {
      e.preventDefault();
    }
    saveLocalDraft();
  };

  const saveLocalDraft = () => {
    const vals = Object.keys(values);
    if (vals.length > 0) {
      localStorage.setItem(`draft`, StoreData.encrypt({ formKey, ...values }));
    }
  };

  useEffect(() => {
    loadLocalDraft();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      saveDraft();
    }, 2000);
    return () => clearTimeout(timer);
  }, [values]);

  return <></>;
}
