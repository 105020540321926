import React, { useContext, useState, useEffect } from 'react';

import { FormContext } from '../Form/context';

import Row from '../Container/row';
import Col from '../Container/column';

import loadComponents from '../Loadable';
import AutoSave from './autosave';
import Terms from './terms';

const Fields = loadComponents('form-fields');
const ButtonInput = loadComponents('button-input');
const Alert = loadComponents('alert');
const Alerts = loadComponents('form-alerts');

export default function ApplicationForm({
  name,
  terms,
  customTerms,
  form,
  stage,
  prevStage,
  query,
  setAdditional,
}) {
  const {
    handleSubmit,
    values,
    getFieldId,
    checkCondition,
    processing,
    toggleProcessing,
    formError,
    failed,
    setFailed,
  } = useContext(FormContext);

  const formKey = name
    .replace(/[^A-Z0-9-]/gi, ' ')
    .replace(/\s+/g, '_')
    .toLowerCase();

  const requiredFields = form[stage].fields
    .filter(x => !x.condition || (x.condition && checkCondition(x.condition)))
    .filter(x => x.required)
    .map(y => {
      return { id: y.id, name: y.name };
    });

  const [disabled, setdisabled] = useState(
    requiredFields && requiredFields.length > 0,
  );

  const getRequired = () => {
    const required = requiredFields.map(field => {
      const fieldId = field.id || getFieldId(field.name);
      return { name: field.name, id: fieldId, value: values[fieldId] };
    });

    return required.filter(
      item => item.value === undefined || item.value === '',
    );
  };

  useEffect(() => {
    const missing = getRequired();
    const isDisabled = missing.length > 0;
    setdisabled(isDisabled);
    if (failed) {
      setFailed(isDisabled);
      formError(
        missing.length > 0 && {
          message: `Required fields missing: ${missing
            .map(item => item.name)
            .join(', ')}`,
        },
      );
    }
  }, [values, stage]);

  useEffect(() => {
    if (processing) {
      toggleProcessing();
      setFailed(false);
    }
  }, [stage]);

  const isFinal = form.length === stage + 1;

  const autoSaveProps = {
    formKey,
    query,
  };

  const start = isFinal ? 0 : stage;
  const end = isFinal ? form.length : stage + 1;

  const startAdditional = e => {
    e.preventDefault();
    setAdditional(true);
    localStorage.removeItem(`draft`);

    setTimeout(() => {
      handleSubmit(e);
    }, 500);
  };

  const nextStage = e => {
    const missing = getRequired();
    if (missing.length > 0) {
      e.preventDefault();
      setFailed(true);
      formError({
        message: `Required fields missing: ${missing
          .map(item => item.name)
          .join(', ')}`,
      });
    }

    return e;
  };

  return (
    <form
      name={formKey}
      onSubmit={handleSubmit}
      className={failed && 'missing'}
    >
      <AutoSave {...autoSaveProps} />
      {isFinal && (
        <Alert info style={alertStyle}>
          This page is for final application information checks. This will show
          all answers you have submitted on one page, please read carefully to
          ensure all information is correct. If you need to, you will be able to
          make changes before you proceed and pay. Once you are happy all
          information is correct you may then proceed to the payment page and
          submit your application
        </Alert>
      )}
      {form.slice(start, end).map((group, index) => (
        <div
          className={`group ${isFinal && index !== 0 ? `final` : ``}`}
          key={group.title}
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            marginLeft: `-15px`,
            marginRight: `-15px`,
            paddingTop: index === 0 && 0,
          }}
        >
          <Fields data={group.fields} application stage={stage} />
        </div>
      ))}
      {/* isFinal && */ terms && <Terms custom={customTerms} />}
      <div style={{ marginTop: `30px` }}>
        <Alerts />
      </div>
      <Row justify="space-between" align="center" style={{ marginTop: `15px` }}>
        {stage !== 0 && stage + 1 !== form.length && (
          <Col md={{ width: `auto` }}>
            <ButtonInput
              value="Previous"
              disabled={processing}
              onClick={e => prevStage(e)}
              orange
            />
          </Col>
        )}
        {isFinal ? (
          <>
            <Col md={{ width: `50%` }}>
              <ButtonInput
                value="+ Add another applicant"
                onClick={startAdditional}
                processing={processing}
                disabled={disabled}
                primary
                hollow="true"
              />
            </Col>
            <Col
              className="secondaryBtn"
              md={{ width: `50%` }}
              style={{ textAlign: `right` }}
            >
              <ButtonInput
                value="Proceed to payment"
                processing={processing}
                disabled={disabled}
                primary
              />
            </Col>
          </>
        ) : (
          <Col
            className="secondaryBtn"
            md={{ width: `auto` }}
            style={{ marginLeft: `auto` }}
          >
            <ButtonInput
              value="Next"
              onClick={nextStage}
              processing={processing}
              // disabled={disabled}
              primary
            />
          </Col>
        )}
      </Row>
    </form>
  );
}

const alertStyle = {
  marginBottom: `60px`,
};
