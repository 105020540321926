import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import { BorderRadius } from '../../utils/variables';

export default function Terms({ custom }) {
  const data = useStaticQuery(graphql`
    query TermsConditionsQuery {
      wpPage(slug: { eq: "terms-conditions" }) {
        content
      }
    }
  `);
  return (
    <div style={{ marginTop: `30px` }}>
      <strong style={{ fontSize: `22px`, marginBottom: `15px` }}>
        Terms & Conditions
      </strong>
      <p>
        By submitting this application you confirm that you have read and agree
        to the terms and conditions below:
      </p>
      <div
        style={{
          height: `350px`,
          border: `1px solid rgba(0,0,0,0.2)`,
          borderRadius: BorderRadius,
          overflowY: `auto`,
          padding: `30px`,
          backgroundColor: `#f9f9f9`,
        }}
      >
        {parse(custom || data.wpPage.content)}
      </div>
    </div>
  );
}
